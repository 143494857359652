import React, { useState } from 'react'
import Logo from '../Assets/Logo.png'
import Menu from '../componentes/Menu';
import Modal from 'react-bootstrap/Modal';
import Footer from '../componentes/Footer';
import { CodeBlock, dracula} from "react-code-blocks";

const Inicio = () => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const cssCode = `.cont-efecto-letra-neon{
            margin: 5px;
            padding: 10px;
            background-color: #282c34;
        }

        .cont-efecto-letra-neon p{
        color: #FFFFFF;
        text-align: center;
        line-height: 1.1 !IMPORTANT;
        box-shadow: none !important;
        font-size: 17px ;
        font-weight: normal !important;
        text-shadow: 0 0 5px #fd2626, 0 0 10px #fd2626, 0 0 20px #fd2626,  0 0 40px #fd2626, 
                     0 0 80px #fd2626,  0 0 90px #fd2626, 0 0 100px #fd2626, 0 0 150px #fd2626;
        margin-bottom: 0px;
      }`;

  const htmlCode = `<div className="cont-efecto-letra-neon">
                    <p>Letra con efecto neon</p>
                </div>`;

  const htmlCode_1 = `<button className="custom_add_to_cart_button ">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    Registrar
</button>`;

  const cssCode_1 = `.custom_add_to_cart_button {
    cursor: pointer;
    position: relative;
    display: block;
    padding: 15px 10px;
    margin: auto;
    width: 200px;
    color: #b39659;
    background-color: #b5b5b540 !important;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.05s;
    letter-spacing: 4px;
    overflow: hidden;
    font-weight: bold;
    border: none;
  }`;

  const cssCode_2 = `.custom_add_to_cart_button span {
    position: absolute;
    display: block;
  }
  
  .custom_add_to_cart_button span:nth-child(1) {
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #be5576);
    animation: animate1 0.5s linear infinite;
  }`;

  const cssCode_3 = ` @keyframes animate1 {
    0% {
      left: -100%;
    }
  
    100% {
      left: 100%;
    }
  }
  
  .custom_add_to_cart_button span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #be5576);
    animation: animate2 0.5s linear infinite;
    animation-delay: 0.15s;
  }`;

  const cssCode_4 = `@keyframes animate2 {
    0% {
      top: -100%;
    }
  
    50%,
    100% {
      top: 100%;
    }
  }
  
  .custom_add_to_cart_button span:nth-child(3) {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #be5576);
    animation: animate3 0.5s linear infinite;
    animation-delay: 0.25s;
  }`;

  const cssCode_5 = `@keyframes animate3 {
    0% {
      right: -100%;
    }
  
    50%,
    100% {
      right: 100%;
    }
  }
  
  
  .custom_add_to_cart_button span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #be5576);
    animation: animate4 0.5s linear infinite;
    animation-delay: 0.50s;
  }`;

  const cssCode_6 = `@keyframes animate4 {
    0% {
      bottom: -100%;
    }
  
    50%,
    100% {
      bottom: 100%;
    }
  }`;

  return (
    <>
      <div className='header_1'>
        <img src={Logo} alt='' className='Logo' />
        <p>Biblioteca</p>
      </div>
      <div className='main_1'>
        <div className='columna_1'>
          <Menu />
        </div>
        <div className='columna_2'>
          <p className='titulo_1'>CSS</p>
          <div className='grid-enlaces-interes'>
            <div className='card-css_1'>
              <button className="custom_add_to_cart_button ">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Registrar
              </button>
              <button className='btn-ver-codigo' onClick={handleShow}>Ver codigo</button>
            </div>
            <Modal show={show} onHide={handleClose} size="xl">
              <Modal.Header closeButton>
                <Modal.Title>Animacion 1</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='grid-imagenes-css1'>
                  <CodeBlock
                    text={htmlCode_1}
                    language="html"
                    showLineNumbers
                    theme={dracula}
                  />
                  <CodeBlock
                    text={cssCode_1}
                    language="css"
                    showLineNumbers
                    theme={dracula}
                  />
                  <CodeBlock
                    text={cssCode_2}
                    language="css"
                    showLineNumbers
                    theme={dracula}
                  />
                  <CodeBlock
                    text={cssCode_3}
                    language="css"
                    showLineNumbers
                    theme={dracula}
                  />
                  <CodeBlock
                    text={cssCode_4}
                    language="css"
                    showLineNumbers
                    theme={dracula}
                  />
                  <CodeBlock
                    text={cssCode_5}
                    language="css"
                    showLineNumbers
                    theme={dracula}
                  />
                  <CodeBlock
                    text={cssCode_6}
                    language="css"
                    showLineNumbers
                    theme={dracula}
                  />
                </div>
              </Modal.Body>
            </Modal>
            <div className='card-css_1'>
              <div className='cont-efecto-letra-neon'>
                <p>Letra con efecto neon</p>
              </div>
              <button className='btn-ver-codigo' onClick={handleShow1}>Ver codigo</button>
              <Modal show={show1} onHide={handleClose1} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>Animacion 2</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='grid-imagenes-css1'>
                    <CodeBlock
                      text={htmlCode}
                      language="html"
                      showLineNumbers
                      theme={dracula}
                    />
                    <CodeBlock
                      text={cssCode}
                      language="css"
                      showLineNumbers
                      theme={dracula}
                    />
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Inicio