import React from 'react'
import { FaHome } from 'react-icons/fa';
import { FaCss3Alt } from "react-icons/fa";
import { FaPhp } from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io5";
import { SiReact } from "react-icons/si";
import { Link } from 'react-router-dom';

const Menu = () => {
    return (
        <div>
            <nav className='Menu'>
                <ul>
                    <li><Link to='/'><FaHome /> Inicio</Link></li>
                    <li><Link to='/CSS'><FaCss3Alt /> Css</Link></li>
                    <li><FaPhp /> PHP</li>
                    <li><IoLogoJavascript /> <Link to='/Javascript'>Javascript</Link></li>
                    <li><SiReact /> React</li>
                </ul>
            </nav>
        </div>
    )
}

export default Menu