import React from 'react'

const Footer = () => {
    return (
        <div className='footer'>
            <p>Copyright © 2024 TECHMANIATIC Todos los derechos reservados.</p>
        </div>
    )
}

export default Footer