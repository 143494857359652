import React from 'react'
import Logo from '../Assets/Logo.png'
import jsonData from '../componentes/enlaces.json'
import Footer from '../componentes/Footer';
import Menu from '../componentes/Menu';
import { Link } from 'react-router-dom';

const Inicio_1 = () => {
    return (
        <>
            <div className='header_1'>
                <img src={Logo} alt='' className='Logo' />
                <p>Biblioteca</p>
            </div>
            <div className='main_1'>
                <div className='columna_1'>
                    <Menu />
                </div>
                <div className='columna_2'>
                    <p className='titulo_1'>Enlace de interes</p>
                    <div className='container'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">enlace</th>
                                </tr>
                            </thead>
                            <tbody>
                                {jsonData.links.map(item => (
                                    <tr key={item.id}>
                                        <th scope="row">{item.id}</th>
                                        <td>{item.nombre}</td>
                                        <td><Link to={item.enlace}>{item.enlace}</Link></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Inicio_1