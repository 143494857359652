import React, { useRef, useState, useEffect } from 'react'
import Logo from '../Assets/Logo.png'
import Modal from 'react-bootstrap/Modal';
import img_css_01 from '../Assets/01.png';
import img_css_02 from '../Assets/02.png';
import img_css_03 from '../Assets/03.png';
import img_css_04 from '../Assets/04.png';
import Footer from '../componentes/Footer';
import Menu from '../componentes/Menu';
import { CodeBlock, dracula } from "react-code-blocks";

const Javascript = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        const bubbles = [];

        class Bubble {
            constructor() {
                this.radius = Math.random() * 20 + 10;
                this.x = Math.random() * canvas.width;
                this.y = Math.random() * -canvas.height; // Cambiar aquí la posición inicial
                this.color = '#b39659';
                this.speed = Math.random() * 2 + 0.5;
                this.zIndex = -9999999;
                this.boxShadow = "0 0 5px #999999";
            }
            draw() {
                ctx.globalAlpha = 0.2; // Ajustar el valor para cambiar la opacidad (valor entre 0 y 1)
                ctx.beginPath();
                ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
                ctx.fillStyle = this.color;
                ctx.fill();
                ctx.globalAlpha = 1;
            }

            update() {
                this.y += this.speed; // Cambiar aquí la dirección de movimiento
                if (this.y > canvas.height + this.radius) { // Cambiar aquí la condición de reposicionamiento
                    this.y = Math.random() * -canvas.height;
                    this.x = Math.random() * canvas.width;
                }
            }
        }

        function init() {
            for (let i = 0; i < 50; i++) {
                bubbles.push(new Bubble());
            }
        }

        function animate() {
            requestAnimationFrame(animate);
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            // ctx.filter = 'blur(2px)';
            bubbles.forEach(bubble => {
                bubble.draw();
                bubble.update();
            });
        }

        init();
        animate();
    }, []);


    const htmlCode = `<canvas id="canvas"></canvas>`;

    const cssCode = `#canvas {
        position: absolute;
    }`;

    const javascriptCode = `<script> 
    const canvas = document.getElementById('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const bubbles = [];

    class Bubble {
        constructor() {
            this.radius = Math.random() * 20 + 10;
            this.x = Math.random() * canvas.width;
            this.y = Math.random() * -canvas.height; // Cambiar aquí la posición inicial
            this.color = '#b39659';
            this.speed = Math.random() * 2 + 0.5;
            this.zIndex = -9999999;
            this.boxShadow = "0 0 5px #999999";
        }
        draw() {
            ctx.globalAlpha = 0.2; // Ajustar el valor para cambiar la opacidad (valor entre 0 y 1)
            ctx.beginPath();
            ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
            ctx.fillStyle = this.color;
            ctx.fill();
            ctx.globalAlpha = 1; 
        }`;

    const javascriptCode_1 = `update() {
        this.y += this.speed; // Cambiar aquí la dirección de movimiento
        if (this.y > canvas.height + this.radius) { // Cambiar aquí la condición de reposicionamiento
            this.y = Math.random() * -canvas.height;
            this.x = Math.random() * canvas.width;
        }
    }
}

function init() {
    for (let i = 0; i < 50; i++) {
        bubbles.push(new Bubble());
    }
}

function animate() {
    requestAnimationFrame(animate);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    // ctx.filter = 'blur(2px)';
    bubbles.forEach(bubble => {
        bubble.draw();
        bubble.update();
    });
}

init();
animate();
</script>`;

    return (
        <>
            <div className='header_1'>
                <img src={Logo} alt='' className='Logo' />
                <p>Biblioteca</p>
            </div>
            <div className='main_1'>
                <div className='columna_1'>
                    <Menu />
                </div>
                <div className='columna_2'>
                    <p className='titulo_1'>Javascript</p>
                    <div className='grid-enlaces-interes'>
                        <div className='card-css_1'>
                            <Modal show={show} onHide={handleClose} size="xl">
                                <Modal.Header closeButton>
                                    <Modal.Title>Animacion 1 (javascript)</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='grid-imagenes-css1'>
                                        <CodeBlock
                                            text={htmlCode}
                                            language="html"
                                            showLineNumbers
                                            theme={dracula}
                                        />
                                        <CodeBlock
                                            text={cssCode}
                                            language="css"
                                            showLineNumbers
                                            theme={dracula}
                                        />
                                        <CodeBlock
                                            text={javascriptCode}
                                            language="javascript"
                                            showLineNumbers
                                            theme={dracula}
                                        />
                                       <CodeBlock
                                            text={javascriptCode_1}
                                            language="javascript"
                                            showLineNumbers
                                            theme={dracula}
                                        />
                                    </div>
                                </Modal.Body>
                            </Modal>
                            <canvas ref={canvasRef} className='canva1' />
                            <button className='btn-ver-codigo' onClick={handleShow}>Ver codigo</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Javascript