import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Javascript from '../Vistas/Javascript'
import Css from '../Vistas/Css'
import Inicio_1 from '../Vistas/Inicio_1'

const AppRouting = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Inicio_1 />} />
                    <Route path='/CSS' element={<Css />} />
                    <Route path='/Javascript' element={<Javascript />} />
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default AppRouting